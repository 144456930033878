<template>
  <div>
    <div class="row">
      <div class="col-sm-10 offset-sm-1">
        <div class="row">
          <h1 class="col-sm-12">Documentación básica</h1>
        </div>
        <div class="rowmb-4">
          <div class="col-sm-12">Deacuerdo a la documentación proporcionada en la opción de "Ver documentos" en el botón superior derecho, señala si es "No viable" o "Viable". De ser viable te enviará al siguiente paso de no serlo se eliminará la solicitud de tu lista y se enviará a revisión</div>
        </div>
        <div class="row">
          <div class="col-sm-12 text-right">
            <button class="btn btn-warning mr-2" @click="$emit('retroceder')">Retroceder etapa</button>
            <button class="btn btn-secondary mr-2" @click="no_viable=true">No viable</button>
            <button class="btn btn-success" @click="$emit('avanzar')">Viable</button>
          </div>
        </div>
      </div>
    </div>

    <NoViable v-if="no_viable" @cancelar="no_viable=false"/>
  </div>
</template>

<script type="text/javascript">
  import NoViable from '@/apps/formalizacion/Componentes/NoViable'

  export default {
    components: {
      NoViable
    }
    ,data() {
      return {
        no_viable: false
      }
    }
  }
</script>